<template>
  <v-container
    id="matriculados-caplp"
    fluid
    grid-list-xl
  >
    <v-layout>
      <!-- <v-flex
        xs12
        md6
        class="alineaizquierda"
      >
        <h3>Consulta de Matriculados</h3>
      </v-flex> -->
      <v-flex
        xs6
        md10
        class="alineaderecha"
      >
        <v-btn
          class="ma-2"
          fab
          dark
          small
          color="primary"
          @click="ListarMatriculados"
        >
          <v-icon
            dark
          >
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-flex>
      <v-flex
        xs6
        md2
        class="alineaizquierda"
      >
        <v-btn
          class="ma-2"
          fab
          dark
          small
          color="secondary"
          @click="Exportar"
        >
          <v-icon
            dark
          >
            mdi-printer
          </v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <v-divider/>
      </v-flex>
    </v-layout>
    <!-- <v-layout>
      <v-flex>
        <v-expansion-panel>
          <v-expansion-panel-content>
            <template v-slot:header>
              <div>Tipo de Listado</div>
            </template>
            <v-card>
              <v-layout class="altojusto">
                <v-flex
                  cols12
                >
                  <v-select
                    v-model="parametros.idtiporeporte"
                    :items="listatiposreporte"
                    label="Tipo Reporte"
                    outlined
                    item-text="tipo"
                    item-value="idtipo"
                    dense
                    @change="CambiaTipoReporte"
                  />
                </v-flex>
              </v-layout>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
    </v-layout> -->
    <v-layout>
      <v-flex>
        <v-expansion-panel>
          <v-expansion-panel-content>
            <template v-slot:header>
              <div>Filtros</div>
            </template>
            <v-card>
              <!-- <v-card-text>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</v-card-text> -->
              <v-layout class="altojusto">
                <v-flex
                  xs12
                  md4
                >
                  <v-text-field
                    v-model="parametros.apenom"
                    label="Apellido y Nombre"
                    outlined
                    dense
                    hide-details
                    single-line
                  />
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-menu
                    ref="menuftitd"
                    v-model="menuftitd"
                    :close-on-content-click="false"
                    :return-value.sync="parametros.fechatitulodesde"
                    transition="scale-transition"
                    offset-y
                    min-width="120px"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field
                        v-model="parametros.fechatitulodesde"
                        v-bind="attrs"
                        label="F. Titulo Desde"
                        width="120px"
                        outlined
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="parametros.fechatitulodesde"
                      no-title
                      scrollable
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="menuftitd = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuftitd.save(parametros.fechatitulodesde)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-menu
                    ref="menuftith"
                    v-model="menuftith"
                    :close-on-content-click="false"
                    :return-value.sync="parametros.fechatitulohasta"
                    transition="scale-transition"
                    offset-y
                    min-width="120px"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field
                        v-model="parametros.fechatitulohasta"
                        v-bind="attrs"
                        label="F. Titulo Hasta"
                        width="120px"
                        outlined
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="parametros.fechatitulohasta"
                      no-title
                      scrollable
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="menuftith = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuftith.save(parametros.fechatitulohasta)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-menu
                    ref="menuftitmedd"
                    v-model="menuftitmedd"
                    :close-on-content-click="false"
                    :return-value.sync="parametros.fechatitulomeddesde"
                    transition="scale-transition"
                    offset-y
                    min-width="120px"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field
                        v-model="parametros.fechatitulomeddesde"
                        v-bind="attrs"
                        label="F. Tit. Med. Desde"
                        width="120px"
                        outlined
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="parametros.fechatitulomeddesde"
                      no-title
                      scrollable
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="menuftitmedd = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuftitmedd.save(parametros.fechatitulomeddesde)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-menu
                    ref="menuftitmedh"
                    v-model="menuftitmedh"
                    :close-on-content-click="false"
                    :return-value.sync="parametros.fechatitulomedhasta"
                    transition="scale-transition"
                    offset-y
                    min-width="120px"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field
                        v-model="parametros.fechatitulomedhasta"
                        v-bind="attrs"
                        label="F. Tit. Med. Hasta"
                        width="120px"
                        outlined
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="parametros.fechatitulomedhasta"
                      no-title
                      scrollable
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="menuftitmedh = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menuftitmedh.save(parametros.fechatitulomedhasta)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout class="altojusto">
                <v-flex
                  xs12
                  md4
                >
                  <v-text-field
                    v-model="parametros.localidad"
                    label="Localidad"
                    outlined
                    dense
                    hide-details
                    single-line
                  />
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-menu
                    ref="menufmatd"
                    v-model="menufmatd"
                    :close-on-content-click="false"
                    :return-value.sync="parametros.fechamatriculadesde"
                    transition="scale-transition"
                    offset-y
                    min-width="120px"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field
                        v-model="parametros.fechamatriculadesde"
                        v-bind="attrs"
                        label="F. Matric. Desde"
                        width="120px"
                        outlined
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="parametros.fechamatriculadesde"
                      no-title
                      scrollable
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="menufmatd = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menufmatd.save(parametros.fechamatriculadesde)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-menu
                    ref="menufmath"
                    v-model="menufmath"
                    :close-on-content-click="false"
                    :return-value.sync="parametros.fechamatriculahasta"
                    transition="scale-transition"
                    offset-y
                    min-width="120px"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field
                        v-model="parametros.fechamatriculahasta"
                        v-bind="attrs"
                        label="F. Matric. Hasta"
                        width="120px"
                        outlined
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="parametros.fechamatriculahasta"
                      no-title
                      scrollable
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="menufmath = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menufmath.save(parametros.fechamatriculahasta)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-menu
                    ref="menufinsmedd"
                    v-model="menufinsmedd"
                    :close-on-content-click="false"
                    :return-value.sync="parametros.fechainscmeddesde"
                    transition="scale-transition"
                    offset-y
                    min-width="120px"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field
                        v-model="parametros.fechainscmeddesde"
                        v-bind="attrs"
                        label="F. Insc. Med. Desde"
                        width="120px"
                        outlined
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="parametros.fechainscmeddesde"
                      no-title
                      scrollable
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="menufinsmedd = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menufinsmedd.save(parametros.fechainscmeddesde)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-menu
                    ref="menufinsmedh"
                    v-model="menufinsmedh"
                    :close-on-content-click="false"
                    :return-value.sync="parametros.fechainscmedhasta"
                    transition="scale-transition"
                    offset-y
                    min-width="120px"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field
                        v-model="parametros.fechainscmedhasta"
                        v-bind="attrs"
                        label="F. Insc. Med. Hasta"
                        width="120px"
                        outlined
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="parametros.fechainscmedhasta"
                      no-title
                      scrollable
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="menufinsmedh = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menufinsmedh.save(parametros.fechainscmedhasta)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout class="altojusto">
                <v-flex
                  xs12
                  md4
                >
                  <v-text-field
                    v-model="parametros.domicilio"
                    label="Domicilio"
                    outlined
                    dense
                    hide-details
                    single-line
                  />
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-menu
                    ref="menufnacd"
                    v-model="menufnacd"
                    :close-on-content-click="false"
                    :return-value.sync="parametros.fechanacdesde"
                    transition="scale-transition"
                    offset-y
                    min-width="120px"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field
                        v-model="parametros.fechanacdesde"
                        v-bind="attrs"
                        label="F. Nacim. Desde"
                        width="120px"
                        outlined
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="parametros.fechanacdesde"
                      no-title
                      scrollable
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="menufnacd = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menufnacd.save(parametros.fechanacdesde)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-menu
                    ref="menufnach"
                    v-model="menufnach"
                    :close-on-content-click="false"
                    :return-value.sync="parametros.fechanachasta"
                    transition="scale-transition"
                    offset-y
                    min-width="120px"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field
                        v-model="parametros.fechanachasta"
                        v-bind="attrs"
                        label="F. Nacim. Hasta"
                        width="120px"
                        outlined
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="parametros.fechanachasta"
                      no-title
                      scrollable
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="menufnach = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menufnach.save(parametros.fechanachasta)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-menu
                    ref="menufbajamedd"
                    v-model="menufbajamedd"
                    :close-on-content-click="false"
                    :return-value.sync="parametros.fechabajacmeddesde"
                    transition="scale-transition"
                    offset-y
                    min-width="120px"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field
                        v-model="parametros.fechabajacmeddesde"
                        v-bind="attrs"
                        label="F. Baja Med. Desde"
                        width="120px"
                        outlined
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="parametros.fechabajacmeddesde"
                      no-title
                      scrollable
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="menufbajamedd = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menufbajamedd.save(parametros.fechabajacmeddesde)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-menu
                    ref="menufbajamedh"
                    v-model="menufbajamedh"
                    :close-on-content-click="false"
                    :return-value.sync="parametros.fechabajacmedhasta"
                    transition="scale-transition"
                    offset-y
                    min-width="120px"
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field
                        v-model="parametros.fechabajacmedhasta"
                        v-bind="attrs"
                        label="F. Baja Med. Hasta"
                        width="120px"
                        outlined
                        dense
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="parametros.fechabajacmedhasta"
                      no-title
                      scrollable
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="menufbajamedh = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menufbajamedh.save(parametros.fechabajacmedhasta)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout class="altojusto">
                <v-flex
                  xs12
                  md2
                >
                  <v-select
                    v-model="parametros.iddelegacion"
                    :items="listadelegaciones"
                    label="Delegación"
                    item-text="nombre"
                    item-value="iddelegacion"
                    outlined
                    dense
                  />
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-select
                    v-model="parametros.sexo"
                    :items="listasexos"
                    label="Sexo"
                    item-text="sexo"
                    item-value="sexo"
                    outlined
                    dense
                  />
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-select
                    v-model="parametros.cumplemes"
                    :items="listameses"
                    label="Mes"
                    outlined
                    item-text="mes"
                    item-value="idmes"
                    dense
                  />
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-text-field
                    v-model="parametros.cumpleaniodesde"
                    label="Año Desde"
                    outlined
                    dense
                    hide-details
                    single-line
                    type="number"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-text-field
                    v-model="parametros.cumpleaniohasta"
                    label="Año Hasta"
                    outlined
                    dense
                    hide-details
                    single-line
                    type="number"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-select
                    v-model="parametros.estadocm"
                    :items="listaestadosCM"
                    label="Estado CM"
                    outlined
                    item-text="estadocm"
                    item-value="estadocm"
                    dense
                  />
                </v-flex>
              </v-layout>
              <v-layout class="altojusto">
                <v-flex
                  xs12
                  md4
                >
                  <v-select
                    v-model="parametros.idestado"
                    :items="listaestadosmatriculados"
                    label="Estado"
                    item-text="nombre"
                    item-value="idestado"
                    outlined
                    dense
                  />
                </v-flex>
                <v-flex
                  xs12
                  md4
                >
                  <v-select
                    v-model="parametros.idtipomatricula"
                    :items="listatiposmatricula"
                    label="Tipo Mat."
                    outlined
                    item-text="nombre"
                    item-value="idtipomatricula"
                    dense
                  />
                </v-flex>
                <v-flex
                  xs12
                  md4
                >
                  <v-select
                    v-model="parametros.idestadomorosidad"
                    :items="listaestadosmorosidad"
                    label="Estado Morosidad"
                    outlined
                    item-text="nombre"
                    item-value="idestado"
                    dense
                  />
                </v-flex>
              </v-layout>
              <v-layout class="altojusto">
                <v-flex
                  xs12
                  md2
                >
                  <v-select
                    v-model="parametros.idtipofolio"
                    :items="listatiposfolio"
                    label="Tipo Folio"
                    outlined
                    item-text="nombre"
                    item-value="idtipofolio"
                    dense
                  />
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-text-field
                    v-model="parametros.tomo"
                    label="Tomo"
                    outlined
                    dense
                    hide-details
                    single-line
                    type="number"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-text-field
                    v-model="parametros.folio"
                    label="Folio"
                    outlined
                    dense
                    hide-details
                    single-line
                    type="number"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-select
                    v-model="parametros.idestadosaldo"
                    :items="listaestadossaldo"
                    label="Estado Saldo"
                    outlined
                    item-text="nombre"
                    item-value="idestado"
                    dense
                  />
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-text-field
                    v-model="parametros.saldomin"
                    label="Saldo Min."
                    outlined
                    dense
                    hide-details
                    single-line
                    type="number"
                  />
                </v-flex>
                <v-flex
                  xs12
                  md2
                >
                  <v-select
                    v-model="parametros.idestadomatfederal"
                    :items="listamatriculafederal"
                    label="Matricula Federal"
                    outlined
                    item-text="nombre"
                    item-value="idmatfed"
                    dense
                  />
                </v-flex>
              </v-layout>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <v-spacer/>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex
        xs12
        class="alineacentro"
      >
        <v-data-table
          :headers="columnas"
          :items="listamatriculados"
          :items-per-page="10"
          :footer-props="{'items-per-page-text': 'Filas por Página:',
                          'items-per-page-all-text' : 'Todos'}"
          class="elevation-1"
          dense
          no-data-text="Sin Resultados"
          sort-by="['apenom']"
          multi-sort
        >
          <template
            slot="items"
            slot-scope="{ item }"
          >
            <td>{{ item.idmatriculado }}</td>
            <td>{{ item.apenom }}</td>
            <td>{{ item.abogado }}</td>
            <td>{{ item.procurador }}</td>
            <td>{{ item.domicilio }}</td>
            <td>{{ item.localidad }}</td>
            <td>{{ item.telefono }}</td>
            <td>{{ item.documento }}</td>
            <td>{{ item.fechanac }}</td>
            <td>{{ item.fechatit }}</td>
            <td>{{ item.fechamat }}</td>
            <td>{{ item.mfederal }}</td>
            <td>{{ item.matmedins }}</td>
            <td>{{ item.matmedbaja }}</td>
            <td>{{ item.estado }}</td>
            <td>{{ item.estadomorosidad }}</td>
            <td>{{ item.saldo }}</td>
            <td>{{ item.codpostal }}</td>
          </template>
        </v-data-table>
        <v-dialog
          v-model = "loading"
          fullscreen
          full-width
        >
          <v-container
            fluid
            class = "full-height"
            style = "background-color: rgba(255, 255, 255, 0.5);"
          >
            <v-layout
              align="center"
              justify="center"
              style="height:100vh"
              no-gutters
              dense
            >
              <v-flex
                class = "elementocentrado"
              >
                <v-progress-circular
                  indeterminate
                  color = "primary"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-dialog>
        <v-dialog
          v-model="dialogmensaje"
          persistent
          max-width="290"
        >
          <v-card>
            <v-card-title class="headline">
              {{ mensajetitulo }}
            </v-card-title>
            <v-card-text>
              {{ mensajecuerpo }}
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                color="green darken-1"
                text
                @click="dialogmensaje = false"
              >
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogexporta"
          persistent
          max-width="500"
        >
          <v-card>
            <v-card-title class="headline">
              Reporte de Matriculados
            </v-card-title>
            <v-card-text>
              Campos a Mostrar:
              <v-select
                v-if="parametros.idtiporeporte==1"
                v-model="parametros.camposreporte"
                :items="listacampos"
                chips
                label="Campos a Mostrar"
                item-text="nombre"
                item-value="id"
                multiple
                solo
              />
              <v-select
                v-model="parametros.formatoexporta"
                :items="listaformatosexporta"
                label="Formato"
                outlined
                item-text="formato"
                item-value="formato"
                dense
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                color="green darken-1"
                text
                @click="ExportaDatos"
              >
                Exportar
              </v-btn>
              <v-btn
                color="red darken-1"
                text
                @click="dialogexporta = false"
              >
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import axios from '@/axios'
  export default {
    name: 'Matriculados',
    data () {
      return {
        loading: false,
        parametros: {
          apenom: null,
          fechatitulodesde: null,
          fechatitulohasta: null,
          fechatitulomeddesde: null,
          fechatitulomedhasta: null,
          localidad: null,
          fechamatriculadesde: null,
          fechamatriculahasta: null,
          fechainscmeddesde: null,
          fechainscmedhasta: null,
          domicilio: null,
          fechanacdesde: null,
          fechanachasta: null,
          fechabajameddesde: null,
          fechabajamedhasta: null,
          iddelegacion: -1,
          sexo: '(TODOS)',
          cumplemes: -1,
          cumpleaniodesde: null,
          cumpleaniohasta: null,
          estadocm: '(TODOS)',
          idestado: -1,
          idtipomatricula: -1,
          idestadomorosidad: -1,
          idtipofolio: -1,
          tomo: null,
          folio: null,
          idestadosaldo: -1,
          saldomin: 0,
          idestadomatfederal: -1,
          formatoexporta: '',
          camposreporte: [
              'idmatriculado',
              'apenom',
              'abogado',
              'procurador',
              'domicilio',
              'localidad',
              'telefono',
              'documento',
              'fechanac',
              'fechatit',
              'fechamat',
              'mfederal',
              'matmedins',
              'matmedbaja',
              'estado',
              'estadomorosidad',
              'saldo',
              'codpostal'
          ],
          idtiporeporte: 1
        },
        menuftitd: false,
        menuftith: false,
        menuftitmedd: false,
        menuftitmedh: false,
        menufmatd: false,
        menufmath: false,
        menufinsmedd: false,
        menufinsmedh: false,
        menufnacd: false,
        menufnach: false,
        menufbajamedd: false,
        menufbajamedh: false,
        listadelegaciones: [],
        listasexos: [{ sexo: '(TODOS)' }, { sexo: 'VARON' }, { sexo: 'MUJER' }],
        listameses: [
          { idmes: -1, mes: '(TODOS)' },
          { idmes: 1, mes: 'ENERO' },
          { idmes: 2, mes: 'FEBRERO' },
          { idmes: 3, mes: 'MARZO' },
          { idmes: 4, mes: 'ABRIL' },
          { idmes: 5, mes: 'MAYO' },
          { idmes: 6, mes: 'JUNIO' },
          { idmes: 7, mes: 'JULIO' },
          { idmes: 8, mes: 'AGOSTO' },
          { idmes: 9, mes: 'SEPTIEMBRE' },
          { idmes: 10, mes: 'OCTUBRE' },
          { idmes: 11, mes: 'NOVIEMBRE' },
          { idmes: 12, mes: 'DICIEMBRE' }
        ],
        listaestadosCM: [{ estadocm: '(TODOS)' }, { estadocm: 'ACTIVO' }, { estadocm: 'BAJA' }],
        listaestadosmatriculados: [],
        listatiposmatricula: [
          { idtipomatricula: -1, nombre: '(TODOS)' },
          { idtipomatricula: 1, nombre: 'ABOGADO' },
          { idtipomatricula: 2, nombre: 'PROCURADOR' },
          { idtipomatricula: 3, nombre: 'ABOGADO Y PROCURADOR' }
        ],
        listaestadosmorosidad: [],
        listatiposfolio: [
          { idtipofolio: -1, nombre: '(TODOS)' },
          { idtipofolio: 1, nombre: 'ABOGADO' },
          { idtipofolio: 2, nombre: 'PROCURADOR' }
        ],
        listaestadossaldo: [
          { idestado: -1, nombre: '(TODOS)' },
          { idestado: 1, nombre: 'MAT. SIN DEUDA' },
          { idestado: 2, nombre: 'MAT. CON DEUDA' },
          { idestado: 3, nombre: 'MAT. CON DEUDA MAYOR A:' }
        ],
        listamatriculafederal: [
          { idmatfed: -1, nombre: '(TODOS)' },
          { idmatfed: 1, nombre: 'TIENE' },
          { idmatfed: 2, nombre: 'NO TIENE' }
        ],
        columnas: [
          {
            text: 'N°',
            align: 'center',
            sortable: true,
            value: 'idmatriculado',
            width: 10
          },
          {
            text: 'Matriculado',
            align: 'center',
            sortable: true,
            value: 'apenom',
            width: 250
          },
          {
            text: 'Mat. Abog.',
            align: 'center',
            sortable: true,
            value: 'abogado',
            width: 150
          },
          {
            text: 'Mat. Proc.',
            align: 'center',
            sortable: true,
            value: 'procurador',
            width: 150
          },
          {
            text: 'Domic. Constituido',
            align: 'left',
            sortable: true,
            value: 'domicilio',
            width: 300
          },
          {
            text: 'Localidad',
            align: 'left',
            sortable: true,
            value: 'localidad',
            width: 180
          },
          {
            text: 'Telefono',
            align: 'center',
            sortable: true,
            value: 'telefono',
            width: 100
          },
          {
            text: 'Documento',
            align: 'center',
            sortable: true,
            value: 'documento',
            width: 180
          },
          {
            text: 'F. Nacim.',
            align: 'center',
            sortable: true,
            value: 'fechanac',
            width: 100
          },
          {
            text: 'F. Titulo',
            align: 'center',
            sortable: true,
            value: 'fechatit',
            width: 100
          },
          {
            text: 'F. Matricula',
            align: 'center',
            sortable: true,
            value: 'fechamat',
            width: 100
          },
          {
            text: 'M. Federal',
            align: 'center',
            sortable: true,
            value: 'mfederal',
            width: 100
          },
          {
            text: 'Mat Med Insc.',
            align: 'center',
            sortable: true,
            value: 'matmedins',
            width: 100
          },
          {
            text: 'Mat Med Baja.',
            align: 'center',
            sortable: true,
            value: 'matmedbaja',
            width: 100
          },
          {
            text: 'Estado',
            align: 'center',
            sortable: true,
            value: 'estado',
            width: 100
          },
          {
            text: 'Estado Moros.',
            align: 'center',
            sortable: true,
            value: 'estadomorosidad',
            width: 100
          },
          {
            text: 'Saldo',
            align: 'center',
            sortable: true,
            value: 'saldo',
            width: 100
          },
          {
            text: 'Cod Postal',
            align: 'center',
            sortable: true,
            value: 'codpostal',
            width: 100
          }
        ],
        listamatriculados: [],
        dialogmensaje: false,
        mensajetitulo: '',
        mensajecuerpo: '',
        dialogexporta: false,
        listaformatosexporta: [ { formato: 'PDF' }, { formato: 'EXCEL' } ],
        listacampos: [
          { id: 'idmatriculado', nombre: 'ID' },
          { id: 'apenom', nombre: 'Ape y Nom' },
          { id: 'abogado', nombre: 'TyF Abogado' },
          { id: 'procurador', nombre: 'TyF Procurador' },
          { id: 'domicilio', nombre: 'Domicilio' },
          { id: 'localidad', nombre: 'Localidad' },
          { id: 'telefono', nombre: 'Teléfono' },
          { id: 'documento', nombre: 'Documento' },
          { id: 'fechanac', nombre: 'Fecha Nac' },
          { id: 'fechatit', nombre: 'Fecha Tit.' },
          { id: 'fechamat', nombre: 'Fecha Mat.' },
          { id: 'mfederal', nombre: 'M. federal' },
          { id: 'matmedins', nombre: 'Mat. Med. Ins.' },
          { id: 'matmedbaja', nombre: 'Mat. Med. Baja' },
          { id: 'estado', nombre: 'Estado' },
          { id: 'estadomorosidad', nombre: 'Estado Mor.' },
          { id: 'saldo', nombre: 'Saldo' },
          { id: 'codpostal', nombre: 'CP' }
        ],
        listatiposreporte: [
            { idtipo: 1, tipo: 'Matriculados' },
            { idtipo: 2, tipo: 'Matriculados en condiciones de Votar' },
            { idtipo: 3, tipo: 'Matriculados en condiciones de ser elegidos' }
        ]
      }
    },
    mounted () {
      this.loading = true
      this.CompletaCombos()
      this.ListarMatriculados()
    },
    methods: {
      ListarMatriculados () {
        this.loading = true
        this.parametros.formatoexporta = ''
        this.parametros.saldomin = parseFloat(this.parametros.saldomin)
        const params = this.parametros
        axios
          .post('matriculado', params)
          .then((result) => {
            this.listamatriculados = result.data
            this.loading = false
          })
          .catch((error) => {
            this.loading = false
            this.MostrarMensaje('Error', error)
          })
      },
      CompletaCombos () {
        axios
          .get('delegacion')
          .then((result) => {
            this.listadelegaciones = result.data
            this.listadelegaciones.push({ iddelegacion: -1, nombre: "(TODOS)" })
            axios
              .get('estadomatriculado')
              .then((result) => {
                this.listaestadosmatriculados = result.data
                axios
                  .get('estadomorosidad')
                  .then((result) => {
                    this.listaestadosmorosidad = result.data
                  })
                  .catch((error) => {
                    this.MostrarMensaje('Error', error)
                  })
              })
              .catch((error) => {
                this.MostrarMensaje('Error', error)
              })
          })
          .catch((error) => {
            this.MostrarMensaje('Error', error)
          })
      },
      Exportar () {
        this.dialogexporta = true
      },
      MostrarMensaje (titulo, cuerpo) {
        this.mensajetitulo = titulo
        this.mensajecuerpo = cuerpo
        this.dialogmensaje = true
      },
      ExportaDatos () {
        this.loading = true
        this.parametros.saldomin = parseFloat(this.parametros.saldomin)
        const params = this.parametros
        axios
          .post('matriculado', params)
          .then((result) => {
            this.loading = false
            if (result.data.toString().startsWith('Error') === false) {
              window.open(result.data, '_blank')
            } else {
              this.MostrarMensaje('Error', result.data)
            }
          })
          .catch((error) => {
            this.loading = false
            this.MostrarMensaje('Error', error)
          })
      }
    }
  }
</script>
<style>
  .textocentrado{
    text-align: center;
  }
  .elementocentrado{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    padding: 2px !important;
  }
  @media only screen and (min-width: 960px) {
    .altojusto{
      height: 45px !important;
    }
  }
  .alineaizquierda{
    text-align: left;
  }
  .alineacentro{
    text-align: center;
  }
  .alineaderecha{
    text-align: right;
  }
</style>
